var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"px-2",attrs:{"fluid":""}},[_c('v-snackbar',{attrs:{"absolute":"","top":"","timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbarText))]),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{attrs:{"cols":"8"}},[_c('h1',[_vm._v("Onboarding")])]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"items":_vm.status,"prepend-icon":"fa-stream","label":"Filtrar por estado","multiple":"","item-text":"text","item-value":"value","aria-autocomplete":"false","hide-details":"","filled":"","rounded":"","allow-overflow":false,"clearable":""},on:{"change":_vm.getUsers},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}})],1)],1),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"items-per-page":10,"loading":_vm.loading,"search":_vm.$store.state.search,"sort-desc":true,"item-key":".key","sort-by":"created","footer-props":{
          itemsPerPageOptions: [10, 25, 50, 100, 250],
        }},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"ma-5",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"inner-lottie"},[_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":300,"width":400},on:{"animCreated":_vm.handleAnimation}}),_c('p',{class:_vm.$vuetify.theme.dark
                    ? 'subtitle-1 primary--text'
                    : 'subtitle-1 black--text'},[_vm._v(" Cargando Data ")])],1),_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto d-none d-md-block",attrs:{"type":"table-tbody"}})],1)]},proxy:true},{key:`item.bin`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.bins.join())+" ")]}},{key:`item.picture`,fn:function({ item }){return [_c('v-avatar',[_c('v-img',{attrs:{"lazy-src":_vm.defaultImage,"src":item.picture == 'assets/imgs/avatar-default.svg'
                  ? _vm.defaultImage
                  : item.picture,"alt":item.name}})],1)]}},{key:`item.security.lastLogin`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("filterDate")(item.security.lastLogin))+" ")]}},{key:`item.phone`,fn:function({ item }){return [_c('v-chip',{on:{"click":function($event){return _vm.copyToClipboard(item.phone)}}},[_vm._v(" "+_vm._s(item.phone)+" ")])]}},{key:`item.gender`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("filterGender")(item.gender))+" ")]}},{key:`item.created`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("filterDate")(item.created))+" ")]}},{key:`item.onboardingStatus`,fn:function({ item }){return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.onboardingStatus)}},[_vm._v(_vm._s(_vm._f("filterStatus")(item.onboardingStatus)))])]}},{key:`item.options`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"fab":"","dark":_vm.$vuetify.theme.dark,"x-small":"","elevation":0,"color":"info"},on:{"click":function($event){return _vm.check(item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("fas fa-info")])],1)]}}],null,true)},[_c('span',[_vm._v("Revisar")])])]}}],null,true)})],1)],1),(_vm.checkDialog)?_c('v-dialog',{attrs:{"max-width":"1200","persistent":""},model:{value:(_vm.checkDialog),callback:function ($$v) {_vm.checkDialog=$$v},expression:"checkDialog"}},[_c('check-dialog',{attrs:{"item":_vm.selectedUser},on:{"success":_vm.handleSuccess,"cancel":function($event){_vm.checkDialog = false}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }