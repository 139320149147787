<template>
  <v-container fluid class="px-2">
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-row class="pa-0 ma-0">
      <v-col cols="8">
        <h1>Onboarding</h1>
      </v-col>

      <v-col cols="12" md="4">
        <v-select
          v-model="selectedStatus"
          :items="status"
          prepend-icon="fa-stream"
          label="Filtrar por estado"
          multiple
          @change="getUsers"
          item-text="text"
          item-value="value"
          aria-autocomplete="false"
          hide-details
          filled
          rounded
          :allow-overflow="false"
          clearable
        >
        </v-select>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="users"
          :items-per-page="10"
          :loading="loading"
          :search="$store.state.search"
          :sort-desc="true"
          item-key=".key"
          sort-by="created"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div class="inner-lottie">
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>

          <template v-slot:[`item.bin`]="{ item }">
            {{ item.bins.join() }}
          </template>

          <template v-slot:[`item.picture`]="{ item }">
            <v-avatar>
              <v-img
                :lazy-src="defaultImage"
                :src="
                  item.picture == 'assets/imgs/avatar-default.svg'
                    ? defaultImage
                    : item.picture
                "
                :alt="item.name"
              />
            </v-avatar>
          </template>

          <template v-slot:[`item.security.lastLogin`]="{ item }">
            {{ item.security.lastLogin | filterDate }}
          </template>

          <template v-slot:[`item.phone`]="{ item }">
            <v-chip @click="copyToClipboard(item.phone)">
              {{ item.phone }}
            </v-chip>
          </template>

          <template v-slot:[`item.gender`]="{ item }">
            {{ item.gender | filterGender }}
          </template>

          <template v-slot:[`item.created`]="{ item }">
            {{ item.created | filterDate }}
          </template>

          <template v-slot:[`item.onboardingStatus`]="{ item }">
            <v-chip :color="getStatusColor(item.onboardingStatus)">{{
              item.onboardingStatus | filterStatus
            }}</v-chip>
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="mx-1"
                  fab
                  v-on="on"
                  :dark="$vuetify.theme.dark"
                  @click="check(item)"
                  x-small
                  :elevation="0"
                  color="info"
                >
                  <v-icon dark>fas fa-info</v-icon>
                </v-btn>
              </template>
              <span>Revisar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      max-width="1200"
      v-if="checkDialog"
      persistent
      v-model="checkDialog"
    >
      <check-dialog
        :item="selectedUser"
        @success="handleSuccess"
        @cancel="checkDialog = false"
      ></check-dialog>
    </v-dialog>
  </v-container>
</template>
  
  <script>
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import { db } from "@/firebase";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

import checkDialog from "./check.vue";

export default {
  name: "onboarding",
  components: {
    lottie: Lottie,
    checkDialog,
  },
  mixins: [lottieSettings],
  data() {
    return {
      loading: true,
      snackbar: false,
      snackbarText: "",
      defaultImage: require("@/assets/user.svg"),
      users: [],
      checkDialog: false,
      selectedUser: null,
      status: [
        {
          text: "Colocadas",
          value: "placed",
        },
        {
          text: "Completadas",
          value: "completed",
        },
        {
          text: "Rechazadas",
          value: "rejected",
        },

        {
          text: "Revisadas",
          value: "reviewed",
        },
      ],
      selectedStatus: ["placed"],
      headers: [
        {
          text: "",
          value: "picture",
          class: "nowrap",
        },

        {
          text: "Estado de la solicitud",
          value: "onboardingStatus",
        },
        {
          text: "Nombre",
          value: "name",
          class: "nowrap",
        },
        {
          text: "Apellido",
          value: "surname",
          class: "nowrap",
        },
        {
          text: "Teléfono",
          value: "phone",
          class: "nowrap",
        },

        {
          text: "Género",
          value: "gender",
          class: "nowrap",
        },

        {
          text: "Fecha de creación",
          value: "created",
          class: "nowrap",
        },

        {
          text: "Ultimo login",
          value: "security.lastLogin",
          class: "nowrap",
        },

        {
          value: "options",
          class: "nowrap",
          align: "end",
        },
      ],
    };
  },
  async mounted() {
    this.$store.commit("setSearchTerm", "usuario");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;

    await this.$binding(
      "users",
      db
        .collection("users")
        .where("onboardingStatus", "in", this.selectedStatus)
    );

    this.loading = false;
  },
  methods: {
    check(user) {
      this.selectedUser = user;
      this.checkDialog = true;
    },

    async getUsers() {
      await this.$binding(
        "users",
        db
          .collection("users")
          .where("onboardingStatus", "in", this.selectedStatus)
      );
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },

    getStatusColor(e) {
      let colors = {
        completed: "green",
        rejected: "red",
        reviewed: "warning",
        placed: "blue",
      };

      return colors[e] ? colors[e] : "primary";
    },

    handleSuccess() {
      this.snackbarText = "Revisión enviada correctamente.";
      this.snackbar = true;
      this.checkDialog = false;
    },
  },
  watch: {},
  filters: {
    filterStatus(e) {
      let status = {
        placed: "Colocada",
        reviewed: "Revisada",
        completed: "Completada",
        rejected: "Rechazada",
      };

      return status[e] ? status[e] : "Desconocido";
    },
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY HH:mm A");
    },

    filterGender(e) {
      let gender = {
        other2: "Otro",
        male: "Masculino",
        female: "Femenino",
      };

      return gender[e] ? gender[e] : "";
    },
  },
};
</script>
  
  <style scoped>
.inner-lottie {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
  