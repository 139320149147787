<template>
  <v-col cols="12" v-if="!loading" sm="12" md="4">
    <div class="main-o">
      <small
        v-if="item.status == 'placed' && item.edited && !item.accepted"
        class="edited"
        >Editada</small
      >
      <img
        @click="fullDialog = true"
        :lazy-src="placeholder"
        class="pic"
        :src="item.url"
      />

      <div
        v-if="
          (!item.accepted && item.status == 'placed') ||
          (item.edited && !item.accepted && item.status == 'placed')
        "
        class="pos"
      >
        <v-btn @click="accept" class="mr-2" color="#4caf50db">
          <i class="fas fa-check"></i>
        </v-btn>

        <v-btn @click="reject" class="ml-2" color="#ff5252bf"
          ><i class="fas fa-times"></i>
        </v-btn>
      </div>

      <div
        v-if="!item.accepted && item.status != 'placed'"
        class="result rejected"
      >
        <h1>Rechazada</h1>
      </div>

      <div v-if="item.accepted" class="result approved">
        <h1>Aprobada</h1>
      </div>
    </div>

    <!-- <v-dialog
      style="background-color: black"
      max-width="100%"
      v-model="fullDialog"
    > -->
    <div v-if="fullDialog" class="main-zoom">
      <v-icon class="close-zoom" @click="fullDialog = false">
        fas fa-times</v-icon
      >
      <div :style="`transform : rotate(${degree}deg)`">
        <img
          @click="imgZoom == 1 ? zoom('out') : zoom('in')"
          :style="`width: 100%;height: 90vh; object-fit: contain; transform : scale(${imgZoom});padding-top:40px;padding-bottom:40px; cursor: ${
            imgZoom == 1 ? 'zoom-in' : 'zoom-out'
          };`"
          :src="item.url"
        />
      </div>

      <div class="controls">
        <v-btn @click="zoom('in')" icon>
          <v-icon> fas fa-minus</v-icon>
        </v-btn>
        <v-btn icon @click="degree = degree - 90">
          <v-icon> fas fa-undo</v-icon></v-btn
        >
        <v-btn icon @click="degree = degree + 90">
          <v-icon> fas fa-redo</v-icon></v-btn
        >
        <v-btn @click="zoom('out')" icon> <v-icon> fas fa-plus</v-icon></v-btn>
      </div>
    </div>
    <!-- </v-dialog> -->
  </v-col>
</template>


<script>
export default {
  name: "image-onboarding",
  props: ["item"],

  data() {
    return {
      loading: false,
      placeholder: require("@/assets/placeholder.png"),
      fullDialog: false,
      imgZoom: 1,
      degree: 0,
    };
  },
  methods: {
    accept() {
      this.loading = true;
      this.item.accepted = true;
      this.loading = false;

      this.$emit("update", this.item);
    },

    zoom(actions) {
      switch (actions) {
        case "out":
          this.imgZoom = 2;
          break;

        case "in":
          this.imgZoom = 1;
          break;

        default:
          break;
      }
    },

    reject() {
      this.loading = true;
      this.item.accepted = false;
      this.item.status = "reviewed";
      this.loading = false;
      this.$emit("update", this.item);
    },
  },
};
</script>



<style scoped lang="scss">
.pic {
  min-height: 450px;
  max-height: 450px;

  width: 98%;

  cursor: pointer;
}

.main-o {
  justify-content: center;
  display: flex;
  position: relative;
  width: 100%;
  padding: 10px;
}
.pos {
  position: absolute;
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  align-items: center;
  width: 80%;
  bottom: 25px;
}
.approved {
  background-color: rgba(0, 128, 0, 0.376);
}

.rejected {
  background-color: rgba(200, 17, 17, 0.527);
}

.result {
  width: 90%;
  bottom: 10px;
  position: absolute;
  padding: 15px;
}

h1 {
  color: white;
  font-weight: bold;
  margin: 0;
  text-align: center;
}

.fa-check,
.fa-times {
  color: white;
  font-weight: bold;
}

.edited {
  background-color: #FF174487;
  position: absolute;
  right: 5%;
  top: 3%;

  color: white;
  font-weight: bold;

  font-size: 18px;
  padding: 5px;
}

.pos-status {
  position: absolute;
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  align-items: center;
  width: 80%;
  bottom: 25px;
}

.main-zoom {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: fixed;

  z-index: 4;

  top: 0;
  left: 0;

  background-color: black;
  width: 100%;
  min-height: 100vh;

  overflow: auto;
}

.controls {
  background-color: rgb(255, 255, 255);
  position: fixed;
  bottom: 20px;
  width: 200px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 25px;
  padding: 10px;

  .fas {
    margin: 10px 20px;
    color: #ff5700;
    font-weight: bold;
  }
}
.close-zoom {
  // position: fl;

  float: right;
  top: 30px;
  right: 30px;
  position: absolute;

  cursor: pointer;
  z-index: 999;
  font-size: 30px !important;
}

.image-zoomed {
  cursor: zoom-in;
}
</style>