<template>
  <div>
    <v-card class="px-2">
      <v-card-title>
        <span class="headline"> Revisar solicitud </span>

        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          :loading="loading"
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-snackbar top :timeout="3000" v-model="snackbar">
            {{ snackbarText }}
          </v-snackbar>

          <v-row v-if="user">
            <images
              @update="update"
              :item="{
                url: user.onboarding.face,
                accepted: user.onboarding.face_accepted,
                status: user.onboardingStatus,
                edited: user.onboarding.face_edited,
                id: 'face',
              }"
            />

            <images
              @update="update"
              :item="{
                url: user.onboarding.front_id,
                accepted: user.onboarding.front_id_accepted,
                status: user.onboardingStatus,
                edited: user.onboarding.back_id_edited,
                id: 'front',
              }"
            />

            <images
              @update="update"
              :item="{
                url: user.onboarding.back_id,
                accepted: user.onboarding.back_id_accepted,
                edited: user.onboarding.back_id_edited,
                status: user.onboardingStatus,
                id: 'back',
              }"
            />

            <v-col class="mt-0 pt-0" cols="12" sm="12" md="12">
              <v-select
                filled
                v-if="user.onboardingStatus == 'placed'"
                :item-disabled="availableStatus"
                label="Estado de la solicitud"
                rounded
                :items="status"
                v-model="selectedStatus"
              >
                <template v-slot:selection="data">
                  <v-list-item-icon>
                    <v-icon>{{ data.item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title> {{ data.item.text }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <template v-slot:item="data">
                  <v-list-item-icon>
                    <v-icon>{{ data.item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ data.item.description }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>

            <v-col
              v-if="user && user.onboardingStatus != 'placed'"
              cols="12"
              sm="12"
              md="12"
            >
              <h3 class="ml-4">
                <b>Revisado por:</b> {{ user.onboardingReviewedBy.name }}
              </h3>

              <h3 class="ml-4">
                <b>Fecha de revisión:</b>
                {{ user.onboardingReviewedAt | filterDate }}
              </h3>
            </v-col>
          </v-row>

          <v-col
            style="display: flex; justify-content: flex-end"
            class="mt-0 pt-0"
            cols="12"
            sm="12"
            v-if="user && user.onboardingStatus == 'placed'"
            md="12"
          >
            <v-btn @click="save" :loading="loading" color="primary">
              Enviar revisión
            </v-btn>
          </v-col>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
  
  <script>
import { db, fb } from "../../../firebase";
import images from "./image.vue";

import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default {
  name: "check-onboarding",
  components: {
    images,
  },
  props: ["item"],
  data() {
    return {
      loading: false,
      snackbarText: "",
      snackbar: false,
      user: null,
      onboardingFeedback: "",
      selectedStatus: "reviewed",
      status: [
        {
          icon: "fas fa-exclamation",
          text: "Revisado",
          value: "reviewed",
          description:
            "Se revisó la solicitud pero una o más imágenes se han rechazado, el usuario puede subirlas nuevamente.",
        },
        {
          icon: "fas fa-times",
          text: "Rechazado",
          value: "rejected",
          description:
            "Se revisó la solicitud y se determinó rechazarla de forma permanente.",
        },

        {
          icon: "fas fa-check-double",
          text: "Completado",
          value: "completed",
          description:
            "Se revisó la solicitud y el agente facilitador determinó aceptarla.",
        },
      ],
    };
  },

  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY HH:mm A");
    },
  },
  methods: {
    availableStatus(item) {
      if (
        this.user.onboarding.face_accepted &&
        this.user.onboarding.back_id_accepted &&
        this.user.onboarding.front_id_accepted &&
        ["rejected", "reviewed"].includes(item.value)
      ) {
        return true;
      }

      // return true
    },
    save() {
      this.loading = true;

      this.user.onboarding.back_id_edited = false;
      this.user.onboarding.front_id_edited = false;
      this.user.onboarding.face_edited = false;

      db.collection("users")
        .doc(this.user[".key"])
        .update({
          onboarding: this.user.onboarding,
          onboardingStatus: this.selectedStatus,
          onboardingReviewedAt: new Date(),
          onboardingReviewedBy: {
            userId: this.$store.state.user[".key"],
            name: this.$store.state.user.name,
          },
        })
        .then(async () => {
          let httpOnboardingSendPush = fb
            .functions()
            .httpsCallable("httpOnboardingSendPush");

          await httpOnboardingSendPush({
            userId: this.user[".key"],
          });
          this.$emit("success");
        })
        .catch((err) => {
          this.snackbarText =
            "Ocurrió un error al enviar la revisión, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },

    update(e) {
      switch (e.id) {
        case "front":
          this.user.onboarding.front_id_accepted = e.accepted;
          break;

        case "back":
          this.user.onboarding.back_id_accepted = e.accepted;
          break;

        case "face":
          this.user.onboarding.face_accepted = e.accepted;
          break;

        default:
          break;
      }

      if (
        this.user.onboarding.face_accepted &&
        this.user.onboarding.back_id_accepted &&
        this.user.onboarding.front_id_accepted
      ) {
        this.selectedStatus = "completed";
      }
    },
  },

  computed: {},

  mounted() {
    this.user = Object.assign({}, this.item);
    if (this.user.onboardingStatus != "placed") {
      this.selectedStatus = this.user.onboardingStatus;
    }
  },
};
</script>


<style scoped lang="scss">
.fas {
  font-size: 25px !important;
  margin-right: 15px;
}

.fa-check-double {
  color: green;
}

.fa-times {
  color: rgb(142, 0, 0);
}

.fa-exclamation {
  color: rgb(218, 197, 7);
}
</style>
  
