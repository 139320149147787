var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"px-2"},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" Revisar solicitud ")]),_c('v-btn',{staticStyle:{"right":"10px","top":"10px"},attrs:{"icon":"","color":"primary","fab":"","absolute":"","loading":_vm.loading},on:{"click":function($event){return _vm.$emit('cancel')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("fa-times")])],1)],1),_c('v-card-text',[_c('v-container',[_c('v-snackbar',{attrs:{"top":"","timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),(_vm.user)?_c('v-row',[_c('images',{attrs:{"item":{
              url: _vm.user.onboarding.face,
              accepted: _vm.user.onboarding.face_accepted,
              status: _vm.user.onboardingStatus,
              edited: _vm.user.onboarding.face_edited,
              id: 'face',
            }},on:{"update":_vm.update}}),_c('images',{attrs:{"item":{
              url: _vm.user.onboarding.front_id,
              accepted: _vm.user.onboarding.front_id_accepted,
              status: _vm.user.onboardingStatus,
              edited: _vm.user.onboarding.back_id_edited,
              id: 'front',
            }},on:{"update":_vm.update}}),_c('images',{attrs:{"item":{
              url: _vm.user.onboarding.back_id,
              accepted: _vm.user.onboarding.back_id_accepted,
              edited: _vm.user.onboarding.back_id_edited,
              status: _vm.user.onboardingStatus,
              id: 'back',
            }},on:{"update":_vm.update}}),_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"cols":"12","sm":"12","md":"12"}},[(_vm.user.onboardingStatus == 'placed')?_c('v-select',{attrs:{"filled":"","item-disabled":_vm.availableStatus,"label":"Estado de la solicitud","rounded":"","items":_vm.status},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(data.item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(data.item.text))])],1)]}},{key:"item",fn:function(data){return [_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(data.item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(data.item.text))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(data.item.description))])],1)]}}],null,false,1034081097),model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}}):_vm._e()],1),(_vm.user && _vm.user.onboardingStatus != 'placed')?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('h3',{staticClass:"ml-4"},[_c('b',[_vm._v("Revisado por:")]),_vm._v(" "+_vm._s(_vm.user.onboardingReviewedBy.name)+" ")]),_c('h3',{staticClass:"ml-4"},[_c('b',[_vm._v("Fecha de revisión:")]),_vm._v(" "+_vm._s(_vm._f("filterDate")(_vm.user.onboardingReviewedAt))+" ")])]):_vm._e()],1):_vm._e(),(_vm.user && _vm.user.onboardingStatus == 'placed')?_c('v-col',{staticClass:"mt-0 pt-0",staticStyle:{"display":"flex","justify-content":"flex-end"},attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary"},on:{"click":_vm.save}},[_vm._v(" Enviar revisión ")])],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }